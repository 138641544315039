import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from './_components/Error404/Error404';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const AUTH_ROUTES = [
	{ path: '/', exact: true, component: lazy(() => import('./Home/Home')) },
	{ path: '/orders', component: lazy(() => import('./Orders/Route')) },
	{ path: '/users', component: lazy(() => import('./Users/Route')) },
	{ path: '/opportunities', component: lazy(() => import('./Opportunities/Route')) },
	{ path: '/milestones', component: lazy(() => import('./Milestones/Route')) },
	{ path: '/rewards', component: lazy(() => import('./Rewards/Route')) },
	{ path: '/options', component: lazy(() => import('./Options/Options')) },
	{ path: '/assets', component: lazy(() => import('./Assets/Route')) },
	{ path: '/sponsors', component: lazy(() => import('./Sponsors/Route')) },
];

const NO_AUTH_ROUTES = [
	{ path: '/', exact: true, component: lazy(() => import('./Auth/Login')) },
	{ path: '/forgot', component: lazy(() => import('./Auth/Forgot')) },
	{ path: '/reset/:code/:target?', component: lazy(() => import('./Auth/Reset')) },
];

export default function Routes () {
	const { loading, data } = useQuery(gql`
        query GetLoggedIn {
            loggedIn @client
	        viewer { id } # Get viewer to stop this updating before <App />
        }
	`);

	if (!data && loading)
		return null;

	return (
		<Switch>
			{(data.loggedIn ? AUTH_ROUTES : NO_AUTH_ROUTES).map(props => (
				<Route {...props} key={props.path} />
			))}
			{data.loggedIn ? (
				<Route component={Error404} />
			) : (
				<Route render={() => {
					window.localStorage.setItem(
						'kfa_post_login',
						window.location.pathname
					);

					return <Redirect to="/" />;
				}} />
			)}
		</Switch>
	);
};
