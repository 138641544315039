import React from 'react';
import styles from './Loading.module.css';
import { Spin } from 'antd';

export default function Loading () {
	return (
		<div className={styles.loading}>
			<Spin size="large" />
		</div>
	);
}
