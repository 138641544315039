import gql from 'graphql-tag';

export const APP_VIEWER_FRAGMENT = gql`
	fragment AppViewer on Person {
        id
        name
        avatar: assetByAvatarId {
            url(width:100,height:100)
        }
	}
`;
