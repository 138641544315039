import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import ApolloClient from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import gql from 'graphql-tag';
import history from './history';

let client;

// Token
// =========================================================================

export const Token = {
	get () { return window.localStorage.getItem('kfa_token'); },
	set (value) { window.localStorage.setItem('kfa_token', value); },
	clear () { window.localStorage.removeItem('kfa_token'); },
};

// Cache
// =========================================================================

const cache = new InMemoryCache();

cache.writeData({
	data: {
		token: Token.get(),
		loggedIn: !!Token.get(),
	},
});

// Error Link
// =========================================================================

const errorLink = onError(({ graphQLErrors, networkError }) => {
	// TODO: Only console log in dev mode (otherwise send to Sentry)?

	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			),
		);

	if (networkError) {
		client.resetStore();
		console.log(`[Network error]: ${networkError}`);
	}
});

// Request Link
// =========================================================================

const request = async (operation) => {
	const token = Token.get();

	if (!token)
		return;

	operation.setContext({
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const requestLink = new ApolloLink((operation, forward) =>
	new Observable(observer => {
		let handle;
		Promise.resolve(operation)
			.then(oper => request(oper))
			.then(() => {
				handle = forward(operation).subscribe({
					next: observer.next.bind(observer),
					error: observer.error.bind(observer),
					complete: observer.complete.bind(observer),
				});
			})
			.catch(observer.error.bind(observer));

		return () => handle && handle.unsubscribe();
	})
);

// Upload Link
// =========================================================================

const parseHeaders = rawHeaders => {
	const headers = new Headers();
	// Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
	// https://tools.ietf.org/html/rfc7230#section-3.2
	const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");

	preProcessedHeaders.split(/\r?\n/).forEach(line => {
		const parts = line.split(":");
		const key = parts.shift().trim();

		if (key) {
			const value = parts.join(":").trim();
			headers.append(key, value);
		}
	});

	return headers;
};

const uploadFetch = (url, options) => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();

		xhr.onload = () => {
			const opts = {
				status: xhr.status,
				statusText: xhr.statusText,
				headers: parseHeaders(xhr.getAllResponseHeaders() || "")
			};

			opts.url =
				"responseURL" in xhr
					? xhr.responseURL
					: opts.headers.get("X-Request-URL");

			const body = "response" in xhr ? xhr.response : xhr.responseText;

			resolve(new Response(body, opts));
		};

		xhr.onerror = () => {
			reject(new TypeError("Network request failed"));
		};

		xhr.ontimeout = () => {
			reject(new TypeError("Network request failed"));
		};

		xhr.open(options.method, url, true);

		Object.keys(options.headers).forEach(key => {
			xhr.setRequestHeader(key, options.headers[key]);
		});

		if (xhr.upload)
			xhr.upload.onprogress = options.onProgress;

		xhr.send(options.body);
	});
};

const customFetch = (uri, options) => {
	if (options.useUpload)
		return uploadFetch(uri, options);

	return fetch(uri, options);
};

/**
 * ```js
 * mutate({
 *    variables: {
 *      file
 *    },
 *    context: {
 *      fetchOptions: {
 *        useUpload: true,
 *        onProgress: e => {
 *          setProgress(e.loaded / e.total);
 *        }
 *      }
 *    }
 *  })
 * ```
 *
 * @type {ApolloLink}
 */
const uploadLink = createUploadLink({
	uri: 'https://' + (process.env.REACT_APP_TLD || 'api.nextgeneration.kentfa.com') + '/graphql',
	fetch: customFetch,
});

// Client
// =========================================================================

client = new ApolloClient({
	link: ApolloLink.from([
		errorLink,
		requestLink,
		uploadLink,
	]),
	cache,
	connectToDevTools: true,
	ssrMode: false,
	typeDefs: gql`
		extend type Query {
			token: String
			loggedIn: Boolean!
		}
		
		extend type Mutation {
			updateToken (
				token: String!
			): Boolean!
			
			updateLoggedIn (
				loggedIn: Boolean!
			): Boolean!
		}
	`,
	resolvers: {
		Mutation: {
			updateToken: (_root, { token }, { cache }) => {
				cache.writeQuery({
					query: gql`
                        query GetToken {
                            token @client
                        }
					`,
					data: { token },
				});

				Token.set(token);

				return true;
			},
			updateLoggedIn: (_root, { loggedIn }, { cache }) => {
				cache.writeQuery({
					query: gql`
                        query GetLoggedIn {
                            loggedIn @client
                        }
					`,
					data: { loggedIn },
				});

				const postLogin = window.localStorage.getItem('kfa_post_login');
				window.localStorage.removeItem('kfa_post_login');
				if (loggedIn && postLogin)
					setTimeout(() => history.push(postLogin), 0);

				return true;
			},
		},
	}
});

client.onResetStore(() => {
	Token.clear();

	cache.writeData({
		data: {
			token: null,
			loggedIn: false,
		},
	});

	history.push('/');
});

export default client;
