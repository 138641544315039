import React, { useState } from 'react';
import { Icon, Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useRouter } from '../_hooks/useRouter';

const { Sider } = Layout
	, { Item, ItemGroup } = Menu;

export default function AppSidebar () {
	const [isCollapsed, setCollapsed] = useState(false)
		, { location } = useRouter();

	return (
		<Sider
			collapsible
			collapsed={isCollapsed}
			onCollapse={c => setCollapsed(c)}
		>
			<Menu theme="dark" mode="inline" selectedKeys={[
				location.pathname.replace('/','').split('/').shift() || '/'
			]}>
				<ItemGroup>
					<Item key="/">
						<Icon type="home" />
						<span>Home</span>
						<Link to="/" />
					</Item>
					<Item key="orders">
						<Icon type="inbox" />
						<span>Orders</span>
						<Link to="/orders" />
					</Item>
					<Item key="users">
						<Icon type="user" />
						<span>Users</span>
						<Link to="/users" />
					</Item>
				</ItemGroup>
				<ItemGroup title={(
					<span style={{
						fontSize: 11,
						fontWeight: 'bold',
						color: '#fff',
					}}>Configuration</span>
				)}>
					<Item key="opportunities">
						<Icon type="rocket" />
						<span>Opportunities</span>
						<Link to="/opportunities" />
					</Item>
					<Item key="milestones">
						<Icon type="rise" />
						<span>Milestones</span>
						<Link to="/milestones" />
					</Item>
					<Item key="rewards">
						<Icon type="crown" />
						<span>Rewards</span>
						<Link to="/rewards" />
					</Item>
					<Item key="options">
						<Icon type="setting" />
						<span>Options</span>
						<Link to="/options" />
					</Item>
					<Item key="assets">
						<Icon type="picture" />
						<span>Assets</span>
						<Link to="/assets" />
					</Item>
					<Item key="sponsors">
						<Icon type="star" />
						<span>Sponsors</span>
						<Link to="/sponsors" />
					</Item>
				</ItemGroup>
			</Menu>
		</Sider>
	);
}
