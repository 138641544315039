import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import history from './history';
import App from './App/App';
import client from './client';
import Routes from './Routes';
import Loading from './_components/Loading/Loading';
import { Modal } from 'antd';

const __DEV__ = process.env.NODE_ENV === 'development';

!__DEV__ && Sentry.init({
	dsn: 'https://c524aa1a3bd640159fe86a5278a7ff39@sentry.io/1524325',
	release: require('../package.json').version,
});

class ErrorBoundary extends React.Component {

	state = {
		hasError: false,
	};

	static getDerivedStateFromError () {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope(scope => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			Sentry.showReportDialog({ eventId });
		});
	}

	render() {
		return this.props.children;
	}

}

history.listen(() => {
	Modal.destroyAll();
});

const Application = () => (
	<ApolloProvider client={client}>
		<Router history={history}>
			<App>
				<Suspense fallback={<Loading />}>
					<Routes />
				</Suspense>
			</App>
		</Router>
	</ApolloProvider>
);

ReactDOM.render(__DEV__ ? (
	<Application />
) : (
	<ErrorBoundary>
		<Application />
	</ErrorBoundary>
), document.getElementById('root'));
