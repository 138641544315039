import React from 'react';
import { Avatar, Dropdown, Icon, Layout, Menu, Spin } from 'antd';
import styles from './App.module.css';
import AppSidebar from '../_components/AppSidebar';
import { Link } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import history from '../history';
import { APP_VIEWER_FRAGMENT } from '../_graph/Viewer';

const { Header, Content } = Layout;

const AccountMenu = (client, viewer) => (
	<Menu>
		<Menu.Item key="account" onClick={() => history.push(`/users/${viewer.id}`)}>
			My Account
		</Menu.Item>
		<Menu.Item key="logout" onClick={() => client.resetStore()}>
			Logout
		</Menu.Item>
	</Menu>
);

function App ({ children }) {
	const client = useApolloClient();

	const { loading: loggedInLoading, data: { loggedIn } } = useQuery(gql`
		query GetLoggedIn {
			loggedIn @client
		}
	`);

	const { loading: viewerLoading, data: vData } = useQuery(gql`
		query GetViewer ($skip: Boolean!) {
            viewer @skip(if: $skip) {
                ...AppViewer
            }
		}
		${APP_VIEWER_FRAGMENT}
	`, {
		variables: { skip: !loggedIn },
		fetchPolicy: 'network-only',
	});

	const viewer = vData ? vData.viewer : null;

	const loading = loggedInLoading || viewerLoading
		, data = { loggedIn, viewer };

	if (
		((!data || Object.keys(data).length === 0) && loading) ||
		(data && data.loggedIn && !data.viewer)
	) {
		return (
			<div className={styles.loginLayout}>
				<Spin size="large"/>
			</div>
		);
	}

	if (!data.loggedIn)
		return (
			<div className={styles.loginLayout}>
				<div className={styles.loginContainer}>
					<Link to="/">
						<h1 style={{color:'#fff',textAlign:'center'}}>
							Kent FA
						</h1>
					</Link>
					<div className={styles.loginWrap}>{children}</div>
				</div>
			</div>
		);

	const initials = data.viewer.name
		.split(' ')
		.map(w => w.charAt(0).toUpperCase())
		.join('');

	return (
		<Layout style={{minHeight: '100vh'}}>
			<Header style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
				<h1 style={{color:'#fff'}}>Kent FA</h1>

				<Dropdown overlay={AccountMenu(client, data.viewer)} placement="bottomRight">
					<span style={{color:'#fff',cursor:'pointer',lineHeight:'normal'}}>
						<Avatar
							style={{backgroundColor:'#001529'}}
							alt={data.viewer.name}
							title={data.viewer.name}
							src={data.viewer.avatar ? data.viewer.avatar.url : require('../_imgs/avatar.png')}
						>
							{initials}
						</Avatar>
						<Icon type="down" style={{marginLeft:5}} />
					</span>
				</Dropdown>
			</Header>
			<Layout>
				<AppSidebar />
				<Content>
					<span className={styles.corner} />
					{children}
				</Content>
			</Layout>
		</Layout>
	);
}

export default App;
