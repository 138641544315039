import React from 'react';
import styles from './Error404.module.css';
import { Empty } from 'antd';
import Title from '../Title';

export default function Error404 () {
	return (
		<div className={styles.wrap}>
			<Title title="Page not found" />
			<Empty description="Page not found" />
		</div>
	);
}
